import React, {useState, useEffect} from 'react';
import { formatMoney, toggleLoading, validate } from "../../services/helpers";
import { jobFetchJob, jobGetJob } from "../../services/job";
import { userGetLocale, userRequestHeaders } from "../../services/user";
import get from "lodash.get";
import { Button } from "react-bootstrap";
import Wizard from "../Wizard";
import ReturnPaymentStep1 from "../../views/Dashboard/components/Wizards/ReturnPaymentWizard/ReturnPaymentStep1";
import ReturnPaymentStep2 from "../../views/Dashboard/components/Wizards/ReturnPaymentWizard/ReturnPaymentStep2";
import ReturnPaymentStep3 from "../../views/Dashboard/components/Wizards/ReturnPaymentWizard/ReturnPaymentStep3";
import { messages } from "../../views/Dashboard";
import axios from "axios";
import PropTypes from "prop-types";

const OverpaidWizard = (props) => {
    const lng = props.intl.formatMessage;
    const [returnPaymentStep, setReturnPaymentStep] = useState(-1);
    const [retrieveInvoiceError, setRetrieveInvoiceError] = useState({
        message: '',
        type: 'light'
    });
    const [returnPaymentError, setReturnPaymentError] = useState({
        message: '',
        type: 'light'
    });
    
    const [genericMessage, setGenericMessage] = useState({
        message: '',
        type: 'light'
    });
    
    const [overpaidInformation, setOverpaidInformation] = useState({
        show: false,
        account: '',
        amount: '',
        name: '',
        fee: ''
    });
    
    // Handle steps data
    useEffect(() => {
        if (props.step > -1 && props.job.modoverpaid !== null && props.job.modoverpaid) {
            setReturnPaymentStep(props.step);
        } else {
            setOverpaidInformationFromJob(props.job);
            returnPaymentClose();
        }
        
    }, [props.step, props.open]);
    
    
    /**
     * Function for closing the Return payment wizard
     * (Set the current step to -1)
     */
    const returnPaymentClose = () => {
        resetRetrieveInvoiceError();
        resetReturnPaymentError();
        setReturnPaymentStep(-1);
    };
    
    /**
     * Function for moving to next step in Return payment wizard
     */
    const returnPaymentMoveToNextStep = () => {
        resetOverpaidInformation();
        resetGenericMessage();
        resetReturnPaymentError();
        setReturnPaymentStep(returnPaymentStep + 1);
    };
    
    /**
     * Return payment wizard step 3
     */
    const returnPaymentStepThree = () => {
        
        // Reset errors and other notifications
        resetRetrieveInvoiceError();
        resetGenericMessage();
        resetOverpaidInformation();
        
        // Get the form
        const form = document.getElementById('returnpayment-accountdata-form');
        if (typeof form !== 'undefined' && form !== null) {
            
            // Validate form data
            if (validate(form)) {
                // Get the data for request
                const dpid = document.getElementById('returnpayment-dpid')?.value ?? '';
                const iban = document.getElementById('returnpayment-account-number')?.value ?? '';
                const name = document.getElementById('returnpayment-account-holder-name')?.value ?? '';
                const type = document.getElementById('returnpayment-account-type')?.value ?? '';
                const bic = document.getElementById('returnpayment-account-bic')?.value ?? '';
                
                if (dpid !== '' && iban !== '' && name !== '' && type !== '') {
                    let overpaidData = {
                        id: dpid,
                        iban: iban,
                        name: name,
                        type: type,
                        user: {
                            name: '',
                            personid: ''
                        }
                    };
                    
                    if (bic !== '') {
                        overpaidData['bic'] = bic;
                    }
                    
                    submitOverpaid(overpaidData);
                } else {
                    // Error required data was not found
                    setReturnPaymentError({
                        message: lng(messages.unknownError),
                        type: 'danger'
                    });
                }
            }
        } else {
            // Error: Form was not found
            setReturnPaymentError({
                message: lng(messages.unknownError),
                type: 'danger'
            });
        }
    };
    
    /**
     * Get overpaid information for job with notified account number
     * and set it to be displayed to user
     * @param job
     */
    function setOverpaidInformationFromJob(job) {
        // Get locale and currency for formatting
        const userLocale = userGetLocale();
        let currency = get(job, 'currency', false);
        currency = currency && currency !== '' ? currency : process.env.REACT_APP_DEFAULT_CURRENCY;
        
        setOverpaidInformation({
            show: true,
            account: job.overpaid_iban,
            amount: formatMoney(job.modoverpaidamount, userLocale, currency),
            name: job.overpaid_name,
            fee: formatMoney(job.modoverpaidfee, userLocale, currency)
        })
    }
    
    /**
     * Handle errors in overpayment submit
     * @param error
     */
    function handleOverpaidError(error) {
        // Get the error message from response
        const message = typeof error.response?.data.Message !== 'undefined' ? error.response.data.Message.trim() : '';
        
        // Highlight the invalid field
        if (message !== 'Invalid bic') {
            // Highlight Account number field
            const element = document.getElementById('returnpayment-account-number');
            if (!element.classList.contains('is-invalid')) {
                element.classList.add('is-invalid');
            }
        } else {
            // Highlight BIC field
            const element = document.getElementById('returnpayment-account-bic');
            if (!element.classList.contains('is-invalid')) {
                element.classList.add('is-invalid');
            }
        }
        
        let displayError = '';
        
        // Set the error message according to the response
        switch(message) {
            case 'Bank account number is not iban':
            case 'Invalid IBAN (Format)':
                displayError = lng(messages.invalidIbanFormat);
                break;
            
            case 'Invalid Bankgiro':
                displayError = lng(messages.invalidBankgiroFormat);
                break;
            
            case 'Invalid Plusgirot':
            case 'Invalid PlusGirot':
                displayError = lng(messages.invalidPlusgirotFormat);
                break;
            
            case 'Invalid Bban':
                displayError = lng(messages.invalidBbanFormat);
                break;
            
            case 'Unable to get BIC from iban':
                displayError = lng(messages.bicFromIbanFail);
                break;
            
            case 'Invalid bic':
                displayError = lng(messages.bicCodeInvalid);
                break;
    
            case 'Operation in progress':
                displayError = lng(messages.overpaidRefundInProgress);
                break;
    
            default:
                displayError = lng(messages.defaultErrorMessage);
                break;
        }
        
        setReturnPaymentError({
            message: displayError,
            type: 'danger'
        });
    }
    
    /**
     * Reset general dashboard message
     */
    function resetGenericMessage() {
        setGenericMessage({
            message: '',
            type: 'light'
        });
    }
    
    /**
     * Reset return payment process errors
     */
    function resetReturnPaymentError() {
        setReturnPaymentError({
            message: '',
            type: 'light'
        });
    }
    
    /**
     * Reset invoice retrieving errors
     */
    function resetRetrieveInvoiceError() {
        setRetrieveInvoiceError({
            message: '',
            type: 'light'
        });
    }
    
    /**
     * Reset overpaid information
     */
    function resetOverpaidInformation() {
        setOverpaidInformation({
            show: false,
            account: '',
            amount: '',
            name: '',
            fee: ''
        });
    }
    
    /**
     * Sends a bank account number for returning an overpayment
     * @param overpaidData
     */
    function submitOverpaid(overpaidData) {
        
        // Reset errors and other notifications
        resetRetrieveInvoiceError();
        resetGenericMessage();
        resetOverpaidInformation();
        
        // Shows the loading animation "Saving..."
        toggleLoading(true, lng(messages.actionSaving));
        
        axios({
            method: 'post',
            url: process.env.REACT_APP_API_URL + '/online/bankaccount',
            headers: userRequestHeaders(),
            data: JSON.stringify(overpaidData)
        })
            .then(function (res) {
                
                // Payment return process was successful
                jobFetchJob(overpaidData.id)
                    .then(function (response) {
                        
                        // Get job data for displaying success message
                        const job = jobGetJob();
                        // Close the wizard
                        returnPaymentClose();
                        // Turn off loading
                        toggleLoading(false);
                        
                        if (job !== null) {
                            // Display success message
                            setOverpaidInformationFromJob(jobGetJob());
                            props.overpaidInfo(overpaidInformation);
                        } else {
                            // Error: Job was not found
                            // Payment return process was successful, but job data can't be displayed to user
                            setGenericMessage({
                                message: 'Olemme vastaanottaneet ilmoittamasi tilinumeron.',
                                type: 'success'
                            });
                        }
                        
                    })
                    .catch(function () {
                        // Error: Job fetch failed
                        // Payment return process was successful, but job data can't be displayed to user
                        toggleLoading(false);
                        setGenericMessage({
                            message: 'Olemme vastaanottaneet ilmoittamasi tilinumeron.',
                            type: 'success'
                        });
                    });
            })
            .catch(function (error) {
                // Error: Payment return process failed, stay in wizard
                toggleLoading(false);
                handleOverpaidError(error);
            });
    }
    
    return (
        <Wizard
            currentStep={ returnPaymentStep }
            steps={ props.isDirectLink ?
                [
                    {
                        title: lng(messages.returnPaymentTitle),
                        returnText: lng(messages.goBack),
                        bodyContent: <ReturnPaymentStep2 intl={ props.intl } />,
                        footerContent: <Button variant='primary' onClick={ returnPaymentMoveToNextStep }>{ lng(messages.nextStep) }</Button>,
                        closeFunction: returnPaymentClose
                    },
                    {
                        title: lng(messages.returnPaymentTitle),
                        returnText: lng(messages.goBack),
                        bodyContent: <ReturnPaymentStep3 intl={ props.intl } error={ returnPaymentError } />,
                        footerContent: <Button variant='primary' onClick={ returnPaymentStepThree }>{ lng(messages.send) }</Button>,
                        closeFunction: returnPaymentClose
                    }
                ]
                :
                [
                    {
                        title: lng(messages.returnPaymentTitle),
                        returnText: lng(messages.goBack),
                        bodyContent: <ReturnPaymentStep1 intl={ props.intl } error={ retrieveInvoiceError } />,
                        footerContent: <Button variant='primary' onClick={ props.returnPaymentStepOne }>{ lng(messages.nextStep) }</Button>,
                        closeFunction: returnPaymentClose
                    },
                    {
                        title: lng(messages.returnPaymentTitle),
                        returnText: lng(messages.goBack),
                        bodyContent: <ReturnPaymentStep2 intl={ props.intl } />,
                        footerContent: <Button variant='primary' onClick={ returnPaymentMoveToNextStep }>{ lng(messages.nextStep) }</Button>,
                        closeFunction: returnPaymentClose
                    },
                    {
                        title: lng(messages.returnPaymentTitle),
                        returnText: lng(messages.goBack),
                        bodyContent: <ReturnPaymentStep3 intl={ props.intl } error={ returnPaymentError } />,
                        footerContent: <Button variant='primary' onClick={ returnPaymentStepThree }>{ lng(messages.send) }</Button>,
                        closeFunction: returnPaymentClose
                    }
                ]
            }
            overPaidInfo={ props.overpaidInfo(overpaidInformation) }
            overPaidMessage={ props.overpaidMessage(genericMessage) }
        />
    );

};
// Property validation
OverpaidWizard.propTypes = {
    intl: PropTypes.object.isRequired,
    step: PropTypes.number,
    job: PropTypes.object,
    open: PropTypes.bool,
    overpaidInfo: PropTypes.func,
    isDirectLink: PropTypes.bool,
    returnPaymentStepOne: PropTypes.any,
    overpaidMessage: PropTypes.func
};

export default OverpaidWizard;