import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Card } from 'react-bootstrap';
import { formatDate } from '../../../../services/helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { far } from '@fortawesome/pro-regular-svg-icons';

import { messages as jobmessages } from '../../messages';
import { JOB_ENERGYTYPE } from '../../../../services/job';

const ServingCard = (props) => {

    const lng = props.intl.formatMessage;
    const [show, setShow] = useState(false);


    useEffect(() => {

        if (props.job !== null && props.show) {
            setShow(true);
        }

    }, [props.job, props.show]);


    // serving details  
    return (
        <>
            {show && (
                <Card id='serving-info' className='invoice-details serving-info-desktop grey-bottom-border-mobile'>
                    <Card.Body>
                        <h3>{lng(jobmessages.servingPanelHeader)}</h3>
                        <ul className='list-unstyled'>
                            {props.servingSigned ? (
                                <li id='serving-signed-date'>
                                    <p><FontAwesomeIcon icon={far.faCircleExclamation} className='neutral-icon' /> {lng(jobmessages.signatureReceived)}</p>
                                    <span className='label'>{lng(jobmessages.signatureDate)}</span>
                                    <p>{formatDate(props.servingSignedDate)}</p>
                                </li>
                            ) : (
                                <li id='document-for-signing'>
                                    {/* open serving modal, if user is authenticated */}
                                    {props.disableServingButton ? (
                                        <p><span className='alert-icon'><FontAwesomeIcon icon={far.faCircleExclamation} /> {lng(jobmessages.documentToSign)}</span></p>
                                    ) : (
                                        <Button id='card-serving-button' className='ps-0 blue-text' variant='tertiary' onClick={() => props.openModal()}>
                                            <FontAwesomeIcon icon={far.faCircleExclamation} /> {lng(jobmessages.documentToSign)}
                                        </Button>
                                    )}
                                    <span className='label'>{lng(jobmessages.moreInformation)}</span>
                                    <p>{props.job.energytype === JOB_ENERGYTYPE.HEATING ? lng(jobmessages.servingInformationHeating) : lng(jobmessages.servingInformation)} </p>
                                </li>
                            )}
                        </ul>
                    </Card.Body>
                </Card>
            )}
        </>
    );
};

ServingCard.propTypes = {
    'intl': PropTypes.object.isRequired,
    'show': PropTypes.bool,
    'job': PropTypes.object,
    'servingSigned': PropTypes.bool,
    'servingSignedDate': PropTypes.string,
    'disableServingButton': PropTypes.bool,
    'openModal': PropTypes.func
};

export default ServingCard;