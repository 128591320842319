import React from "react";
import { defineMessages, injectIntl } from "react-intl";
import PropTypes from 'prop-types';
import { getCookieConsentValue } from "react-cookie-consent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { far } from "@fortawesome/pro-regular-svg-icons";
import { showChatButtons, disableChatButtons } from "../ToggleChatButton";
import CookieConsent from "../CookieConsent";

// Services
import { parseLinkVariable } from "../../services/helpers/index";

// Sentences to be translated
const messages = defineMessages({
    dataPrivacy: {
        id: "Tietosuoja",
        defaultMessage: "Tietosuoja"
    },
    dataPrivacySettings: {
        id: "Tietosuoja-asetukset",
        defaultMessage: "Tietosuoja-asetukset"
    },
    dataPrivacyTitle: {
        id: "Ropo - Tietosuojaseloste",
        defaultMessage: "Ropo - Tietosuojaseloste"
    },
    contact: {
        id: "Yhteystiedot",
        defaultMessage: "Yhteystiedot"
    },
    contactTitle: {
        id: "Ropo - Yhteystiedot",
        defaultMessage: "Ropo - Yhteystiedot"
    },
    contactFaq: {
        id: "Usein kysytyt kysymykset",
        defaultMessage: "Usein kysytyt kysymykset"
    }
});

export { messages };

const Footer = (props) => {
    const lng = props.intl.formatMessage;
    const locale = props.intl.locale;
    const privacyLink = getPrivacyLinkByLocale(locale);
    const contactLink = getContactLinkByLocale(locale);
    const faqLink = getFaqLinkByLocale(locale);

    /**
     * Return the contact title link for given locale if it exists
     * in the environment variable
     *
     * @param locale
     */
    function getContactLinkByLocale(locale) {
        return parseLinkVariable(locale, process.env.REACT_APP_LINK_CONTACT);
    }

    /**
     * Return the FAQ title link for given locale if it exists
     * in the environment variable
     *
     * @param locale
     */
    function getFaqLinkByLocale(locale) {
        return parseLinkVariable(locale, process.env.REACT_APP_LINK_FAQ);
    }

    /**
     * Return the privacy title link for given locale if it exists
     * in the environment variable
     *
     * @param locale
     */
    function getPrivacyLinkByLocale(locale) {
        return parseLinkVariable(locale, process.env.REACT_APP_LINK_PRIVACY);
    }

    /**
     * Handle changes in cookie consent. When accepted, enable chat buttons, 
     * disable buttons when declined.
     */
    function cookiesUpdated() {
        const cookies = getCookieConsentValue() ? true : false;
        const sidemenuChatButton = document.querySelector('#toggle-chat-sidemenu');
        const supportMenuChatButton = document.querySelector('#toggle-chat-window');

        // toggle styles of chat buttons based on the changes in cookie consent made from footer
        if (cookies) {
            showChatButtons(sidemenuChatButton, supportMenuChatButton);
        } else {
            disableChatButtons(sidemenuChatButton, supportMenuChatButton);
        }
    }


    return (
        <div className={"footer" + (props.location.pathname === '/' ? " login-footer" : "")}>
            <nav>
                <ul>
                    {privacyLink ? (
                        <li>
                            <a title={lng(messages.dataPrivacyTitle)} rel="noopener noreferrer"
                                href={privacyLink} target="_blank"
                                className="dataPrivacy-link">
                                <FontAwesomeIcon icon={far.faShieldCheck} /> {lng(messages.dataPrivacy)}
                            </a>
                        </li>
                    ) : <></>}
                    <li>
                        <CookieConsent
                            openedFrom={'footer'}
                            cookiesUpdated={cookiesUpdated}
                            buttonStyling={'footer-cookies'}
                            buttonTitle={lng(messages.dataPrivacySettings)}
                            buttonText={lng(messages.dataPrivacySettings)}
                            {...props}
                        />
                    </li>
                    {contactLink ? (
                        <li>
                            <a title={lng(messages.contactTitle)} rel="noopener noreferrer"
                                href={contactLink} target="_blank"
                                className="contact-link">
                                <FontAwesomeIcon icon={far.faHeadset} /> {lng(messages.contact)}
                            </a>
                        </li>
                    ) : <></>}
                    {faqLink ? (
                        <li>
                            <a title={lng(messages.contactFaq)} rel="noopener noreferrer" href={faqLink}
                                target="_blank"
                                className="contactFaq-link">
                                <FontAwesomeIcon icon={far.faCommentsQuestionCheck} /> {lng(messages.contactFaq)}
                            </a>
                        </li>
                    ) : <></>}
                </ul>
            </nav>
        </div>
    );
};

Footer.propTypes = {
    'intl': PropTypes.object.isRequired,
    'location': PropTypes.shape({
        pathname: PropTypes.string,
    })
};

export default injectIntl(Footer);