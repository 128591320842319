import React, { useEffect, useState } from "react";
import { Button, Modal, Form, FormSelect, FormGroup } from "react-bootstrap";
import { defineMessages } from "react-intl";
import PropTypes from 'prop-types';
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { far } from "@fortawesome/pro-regular-svg-icons";

//services
import { userRequestHeaders, userGetLocale } from "../../../../services/user";
import { mapLocaleToBCP47 } from "../../../../services/helpers";

import AlertDialog from "../../../../components/AlertDialog";


// strings for translations
const messages = defineMessages({
    backToInvoice: {
        id: "Palaa laskun tietoihin",
        defaultMessage: "Palaa laskun tietoihin"
    },
    title: {
        id: "Aloita uusi keskustelu",
        defaultMessage: "Aloita uusi keskustelu"
    },
    chooseTopic: {
        id: "Mitä asiasi koskee?",
        defaultMessage: "Mitä asiasi koskee?"
    },
    authError: {
        id: 'Tunnistautuminen epäonnistui. Ole hyvä ja yritä uudelleen.',
        defaultMessage: 'Tunnistautuminen epäonnistui. Ole hyvä ja yritä uudelleen.'
    },
    notFound: {
        id: "Yhteydenottolomakkeita ei ole saatavilla juuri nyt. Yritä myöhemmin uudelleen.",
        defaultMessage: "Yhteydenottolomakkeita ei ole saatavilla juuri nyt. Yritä myöhemmin uudelleen."
    },
    errorOccurredTryAgain: {
        id: "Tapahtui virhe. Ole hyvä ja yritä uudelleen.",
        defaultMessage: "Tapahtui virhe. Ole hyvä ja yritä uudelleen."
    },
});

export { messages };

const MessageCenterModal = (props) => {
    const lng = props.intl.formatMessage;
    const [forms, setForms] = useState([]);
    const [showAlert, setShowAlert] = useState(false);
    const [alert, setAlert] = useState({
        message: '',
        type: ''
    });

    useEffect(() => {
        // get available messge center forms
        if (forms.length === 0) {
            getForms();
        }

    }, []);

    useEffect(() => {

        // check alerts and hide / show accordingly
        if (alert.message !== '' && alert.type !== '') {
            setShowAlert(true);
        } else {
            setShowAlert(false);
        }

    }, [alert]);

    /**
     * Get supported message center forms
     */
    function getForms() {
        const headers = userRequestHeaders();
        let formOptions = [];

        // clear any error messages
        setAlert({
            message: '',
            type: ''
        });

        // get available message center forms 
        axios({
            method: 'GET',
            url: process.env.REACT_APP_API_URL + '/online/messagecenter/forms?language=' + mapLocaleToBCP47(userGetLocale()),
            headers: headers
        }).then(function (response) {
            formOptions = response.data.form;
            setForms(formOptions);
        }).catch(function (error) {
            if (error.response?.status === 401) {
                // session expired, log user out
                const alert = { message: lng(messages.authError), type: 'danger' };
                props.history.push({
                    pathname: "/logout",
                    alert
                });
            } else if (error.response?.status === 404) {
                // forms not found
                setAlert({
                    // message: 'No available forms found',
                    message: lng(messages.notFound),
                    type: 'warning'
                });

            } else {
                setAlert({
                    message: lng(messages.errorOccurredTryAgain),
                    type: 'danger'
                });
            }
        });
    }


    return (
        <Modal id="message-center-modal" show={props.show} dialogClassName="message-center-form" onHide={props.hide} aria-labelledby="modal-title-message-center-forms">
            <Modal.Header closeButton>
                <Button className="modal-back" onClick={props.hide}>
                    <FontAwesomeIcon icon={far.faArrowLeft} /> {lng(messages.backToInvoice)}
                </Button>
                <Modal.Title as="h4" id="modal-title-message-center-forms">
                    {lng(messages.title)}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {showAlert && (
                    <AlertDialog
                        message={alert.message}
                        type={alert.type}
                    />
                )}
                <Form id="message-center-choose-form">
                    {/* List all available forms */}
                    {forms.length > 0 ? (
                        <FormGroup controlId="message-center-form-option" className="form-floating">

                            <FormSelect id="message-center-form-option" role="select" className="form-control mb-3">
                                {forms.map((option) => {
                                    return (
                                        <option id={option.form_id} key={option.form_id}>{option.form_name}</option>
                                    )
                                })}
                            </FormSelect>
                            <label htmlFor='message-center-form-option'>{lng(messages.chooseTopic)}</label>
                        </FormGroup>
                    ) : ('')}
                </Form>
            </Modal.Body>
        </Modal>
    )
};

MessageCenterModal.propTypes = {
    'intl': PropTypes.object.isRequired,
    'show': PropTypes.bool,
    'hide': PropTypes.func,
    'history': PropTypes.object
};

export default MessageCenterModal;