import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Row } from 'react-bootstrap';

import { messages as jobmessages } from '../../messages';
import InvoiceShortOverview from './InvoiceShortOverview';
import StatusBadge from "../../../../components/StatusBadge";

const InvoiceOverview = (props) => {
    const lng = props.intl.formatMessage;
    const [cardClasses, setCardClasses] = useState('');

    useEffect(() => {

        if (props.job.makepayment !== null && props.job.makepayment) {
            setCardClasses('invoice-details no-rounding-top rounding-top-mobile grey-bottom-border-mobile pt-0 pb-0 pt-md-3');
        } else {
            setCardClasses('invoice-details grey-bottom-border-mobile');
        }

    }, [props.job]);

    // invoice overview
    return (
        <Card className={cardClasses}>
            <Card.Body>
                <Row>
                    <Col md={props.job.makepayment !== null && !props.job.makepayment ? 9 : 12}>
                        <h3>{ lng(jobmessages.invoiceOverview) }</h3>
                    </Col>
                    {props.job.makepayment !== null && !props.job.makepayment && (
                        <Col md={3} className='d-none d-sm-none d-md-block '>
                            <span className='float-end text-end'>
                                <StatusBadge statusCode={props.job.job_main_statuscode} dueDate={props.job.paydate} lng={lng}/>
                            </span>
                        </Col>
                    )}
                    <ul className='list-unstyled mb-0'>
                        
                        {/* in desktop show summarylines (paydate / payplan + invoice related fees) */}
                        <span className='d-none d-sm-none d-md-block'>
                            <InvoiceShortOverview
                                job={props.job}
                                {...props}
                            />
                        </span>
                        <li id='invoice-overview-creditor'>
                            <p>
                                <span className='label'>{lng(jobmessages.creditor)}</span>
                                {props.job.payee.name}
                            </p>
                        </li>
                        <li id='invoice-overview-reference'>
                            <p>
                                <span className='label'>{lng(jobmessages.reference)}</span>
                                {props.job.reference}
                            </p>
                        </li>
                        <li id='invoice-overview-billnum'>
                            <p>
                                <span className='label'>{lng(jobmessages.billNum)}</span>
                                {props.job.billnum ? props.job.billnum : '-'}
                            </p>
                        </li>
                        <li id='invoice-overview-jobid'>
                            <p>
                                <span className='label'>{lng(jobmessages.jobid)}</span>
                                {props.job.jobid}
                            </p>
                        </li>
                    </ul>
                </Row>
            </Card.Body>
        </Card>
    );
};

InvoiceOverview.propTypes = {
    'intl': PropTypes.object.isRequired,
    'job': PropTypes.object,
};

export default InvoiceOverview;