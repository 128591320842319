import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

const AlertDialog = (props) => {
    
    const [message, setMessage] = useState('');
    const [type, setType] = useState('light');
    
    useEffect(() => {
        setMessage(props.message);
        if (typeof props.type !== 'undefined') {
            setType(props.type);
        }
    }, [props.message, props.type]);
    
    return (
        <div className={ "alert " + type } role="alert">
            { message }
        </div>
    );
};

AlertDialog.propTypes = {
    'type': PropTypes.string,
    'message': PropTypes.any,
};

export default AlertDialog;