import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import { userGetLocale } from '../../../../services/user';
import { formatMoney } from '../../../../services/helpers';

import { messages as jobmessages } from '../../messages';

const PaymentDetails = (props) => {

    const lng = props.intl.formatMessage;
    const [classNames, setClassNames] = useState('invoice-details payment-details grey-bottom-border-mobile pt-0 pb-0 pe-4 ps-md-2');

    useEffect(() => {

        if (props.subjob !== undefined) {
            setClassNames('invoice-details payment-details subjob pt-0 pb-0 pe-4 ps-md-0 pe-md-0');
        }

    }, [props.job, props.subjob]);


    // payment information, ie. all fees related to the invoice / subjob
    return (
        <Card className={classNames}>
            <Card.Body className='pb-3 pb-md-0 pe-md-0 ps-md-0 pe-lg-4 ps-lg-4'>
                {props.subjob === undefined && <h5 className='pb-3 d-md-none'>{ lng(jobmessages.paymentDetails) }</h5>}
                <ul className='list-unstyled'>
                    <li id='paymentdetails-openamount'>
                        <p>
                            <span className='label'>{lng(jobmessages.openTotal)}
                                <span className='float-end text-end'> {props.subjob === undefined ? formatMoney(props.job.openamount, userGetLocale(), props.job.currency) : formatMoney(props.subjob.openamount, userGetLocale(), props.subjob.currency)}</span>
                            </span>
                        </p>
                    </li>
                    <li id='paymentdetails-opencapital'>
                        <p>
                            {lng(jobmessages.openCapital)}
                            <span className='float-end text-end'> {props.subjob === undefined ? formatMoney(props.job.opencapital, userGetLocale(), props.job.currency) : formatMoney(props.subjob.opencapital, userGetLocale(), props.subjob.currency)}</span>
                        </p>
                    </li>
                    <li id='paymentdetails-openexpenses'>
                        <p>{lng(jobmessages.openExpenses)}
                            <span className='float-end text-end'>{props.subjob === undefined ? formatMoney(props.job.openexpenses, userGetLocale(), props.job.currency) : formatMoney(props.subjob.openexpenses, userGetLocale(), props.subjob.currency)}</span></p>
                    </li>
                    <li id='paymentdetails-openinterest'>
                        <p> {lng(jobmessages.lateInterest)}
                            <span className='float-end text-end'>{props.subjob === undefined ? formatMoney(props.job.openinterest, userGetLocale(), props.job.currency) : formatMoney(props.subjob.openinterest, userGetLocale(), props.subjob.currency)}</span></p>
                    </li>
                </ul>
            </Card.Body>
        </Card>
    );
};

PaymentDetails.propTypes = {
    'intl': PropTypes.object.isRequired,
    'job': PropTypes.object,
    'subjob': PropTypes.object
};

export default PaymentDetails;