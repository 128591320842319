import React from 'react';
import PropTypes from 'prop-types';
import { Accordion, Card, Col, Row, Tab, Tabs } from 'react-bootstrap';
import { userGetLocale } from '../../../../services/user';
import { formatMoney } from '../../../../services/helpers';

import { messages as jobmessages, debtorStatusMessages } from '../../messages';
import PaymentDetails from './PaymentDetails';
import StatusBadge from "../../../../components/StatusBadge";

const SubJobs = (props) => {

    const lng = props.intl.formatMessage;

    // subjobs 
    return (
        <>
            {props.job.members !== null && (
                <Card id='jobgroup-members' className='invoice-details subjobs grey-bottom-border-mobile'>
                    <Card.Body>
                        <h3 className='card-title'>
                            {lng(jobmessages.jobMembers)}
                        </h3>
                        <p>{ lng(jobmessages.jobMembersDescription) }</p>
                        <Tabs id='group-members' variant='pills' className='pb-4 pt-1'>
                            {props.job.members.map((subjob, index) => (
                                <Tab eventKey={subjob.jobid} title={subjob.jobid} key={'tab-member-' + index}>
                                    <ul className='list-unstyled'>
                                        <li id='subjob-paymentdetails'>
                                            <Accordion defaultActiveKey={'0'} flush>
                                                <Accordion.Item>
                                                    <Accordion.Header>
                                                        <Col md={8} sm={6} xs={6}>
                                                            <span className='label'>
                                                                {lng(jobmessages.openTotal)}
                                                            </span>
                                                            {formatMoney(subjob.openamount, userGetLocale(), subjob.currency)}
                                                        </Col>
                                                        <Col md={{ span: 3, offset: 0 }} className='d-none d-sm-none d-md-block'>
                                                            <span className='label float-end text-end accordion-icon-text'> { lng(jobmessages.showDetails) } </span>
                                                        </Col>
                                                    </Accordion.Header>
                                                    <Accordion.Body className='p-0 ps-md-3 pe-md-4'>
                                                        <PaymentDetails
                                                            subjob={subjob}
                                                            {...props}
                                                        />
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </Accordion>
                                        </li>
                                        <li>
                                            <Row>
                                                <Col md={9} xs={9}>
                                                    <span className='label'>{lng(jobmessages.jobid)}</span> {subjob.jobid}
                                                </Col>
                                                <Col md={3} xs={3}>
                                                    <span className='float-end text-end'>
                                                        <StatusBadge statusCode={props.job.job_main_statuscode} dueDate={props.job.paydate} lng={lng} />
                                                    </span>
                                                </Col>
                                            </Row>
                                        </li>
                                        <li id='subjob-billnum'>
                                            <span className='label'>{lng(jobmessages.billNum)}</span> {subjob.billnum}
                                            {subjob.billnum ? subjob.billnum : '-'}
                                        </li>
                                        {/* possible debtor status messages for sub jobs */}
                                        {typeof debtorStatusMessages['statuscode' + subjob.statuscode] !== "undefined" && debtorStatusMessages['statuscode' + subjob.statuscode] !== "" &&
                                            <li id='subjob-statusmessage'>
                                                <span className='label'>{lng(jobmessages.statusLabel)}</span>
                                                {lng(debtorStatusMessages['statuscode' + subjob.statuscode])}
                                            </li>
                                        }
                                        <li id='subjob-reference'>
                                            <span className='label'>{lng(jobmessages.reference)}</span> {subjob.reference}
                                        </li>
                                        <li id='subjob-creditor'>
                                            <span className='label'>{lng(jobmessages.creditor)}</span> {subjob.payee.name}
                                        </li>
                                    </ul>
                                </Tab>
                            ))}
                        </Tabs>
                    </Card.Body>
                </Card>
            )}
        </>
    );
};

SubJobs.propTypes = {
    'intl': PropTypes.object.isRequired,
    'job': PropTypes.object,
};

export default SubJobs;