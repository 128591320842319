import React, { useEffect, useState } from 'react';
import { Route, HashRouter as Router, Switch } from "react-router-dom";
import { IntlProvider } from 'react-intl';
import * as Sentry from "@sentry/react";

// Menus
import SideMenu from './components/SideMenu/index.js';
import TopMenu from './components/TopMenu/index.js';
import Footer from './components/Footer/index.js';

// Pages
import Dashboard from './views/Dashboard';
import Login from './views/User/Login';
import Logout from './views/User/LogOut';
import Link from './views/User/Link';
import Job from './views/Job/index.js';
import Payment from './views/Payment/';
import SubscriptionPage from "./views/InvoiceSubscriptions/SubscriptionPage";
import LandingPage from "./views/InvoiceSubscriptions/LandingPage";
import NotFound from './views/Error';

// Services
import { getAllowedLangs } from './services/helpers'

// Translationfiles.dsaa
import '@formatjs/intl-pluralrules/locale-data/fi'; // locale data for fi
import '@formatjs/intl-pluralrules/locale-data/en'; // locale data for en
import '@formatjs/intl-pluralrules/locale-data/se'; // locale data for se
import '@formatjs/intl-pluralrules/locale-data/no'; // locale data for no

import locales_fi from './locales/fi.json';
import locales_en from './locales/en.json';
import locales_se from './locales/se.json';
import locales_no from './locales/no.json';

let language = (navigator.languages && navigator.languages[0]) || navigator.language;

// get allowed languages
const ALLOWED_LANGS = getAllowedLangs();

// allowed languages to string for router
const ALLOWED_LANGS_STR = ALLOWED_LANGS.join('|');

// translations
const messages = {
    fi: locales_fi,
    en: locales_en,
    se: locales_se,
    no: locales_no
};

const Main = (props) => {

    const [showTopMenu, setShowTopMenu] = useState(true);
    const [showSideMenu, setShowSideMenu] = useState(false);
    const sideMenuToggle = () => setShowSideMenu(!showSideMenu);

    // language is defined as a global variable and it's exist in global context of application.
    // Every time the main component of the appliction is rendered it must be empty.
    language = '';

    // user's country (locale) is determined by an environmental variable if possible
    let country = process.env.REACT_APP_LOCALE;
    localStorage.setItem('country', country);


    // If locale can be found from localStorage use it as a language.
    if (localStorage.getItem('locale') !== null && ALLOWED_LANGS.includes(localStorage.getItem('locale'))) {
        language = localStorage.getItem('locale');
    }

    // If localstorage did not include the locale value try get default from top level domain (TLD).
    if (!ALLOWED_LANGS.includes(language)) {
        language = new URL(window.location.href).hostname.split('.')[1];
        localStorage.setItem('locale', language);
    }

    // If previous tries did not get determined locale, use browsers defaults.
    if (!ALLOWED_LANGS.includes(language)) {
        // Navigator.language returns 'en-gb' 'fi-en' etc.
        // Because this app has no full support for i18n/l10n functionalities, use only the first 'country' part of array split by '-'.
        language = (navigator.language || navigator.userLanguage).split('-')[0];
        localStorage.setItem('locale', language);
    }

    // If previous tries did not get determined locale, fallback languege to the 'en' by default.
    if (!ALLOWED_LANGS.includes(language)) {
        language = 'en';
        localStorage.setItem('locale', language);
    }

    /**
     * Allowed countries are the same as allowed languages.
     * If the country is not in the list of allowed languages, additional checks are made to determine the country.
     */

    // if country is not determined or allowed, try to get the default from top level domain (TLD)
    if (!ALLOWED_LANGS.includes(country)) {
        country = new URL(window.location.href).hostname.split('.')[1];
        localStorage.setItem('country', country);
    }

    // if TLD doesn't determine the country, use the fallback country
    if (!ALLOWED_LANGS.includes(country)) {
        // fallback country 'fi' by default; affects e.g. the customer service url that will be displayed to the user in FeedbackDialog
        country = 'fi';
        localStorage.setItem('country', country);
    }

    // replace www-prefix with REACT_APP_URL for redirects to work correctly
    if (window.location.hostname.startsWith("www") && typeof process.env.REACT_APP_URL !== 'undefined') {
        window.location = window.location.href.replace(window.location.origin, process.env.REACT_APP_URL);
    }


    useEffect(() => {
        // when component unmounts
        return (() => {

        });

    }, []);


    return (
        <Sentry.ErrorBoundary fallback={"An error has occured"}>
            <IntlProvider locale={language} messages={messages[language]}>
                <Router>
                    <Route render={({ location }) => (
                        <div className="site-container">
                            <div id="loading">
                                <div className="loader-container">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path opacity=".4" d="M256 64C150 64 64 150 64 256s86 192 192 192c70.1 0 131.3-37.5 164.9-93.6l.1 .1c-6.9 14.9-1.5 32.8 13 41.2c15.3 8.9 34.9 3.6 43.7-11.7c.2-.3 .4-.6 .5-.9l0 0C434.1 460.1 351.1 512 256 512C114.6 512 0 397.4 0 256S114.6 0 256 0c-17.7 0-32 14.3-32 32s14.3 32 32 32z"/><path d="M224 32c0-17.7 14.3-32 32-32C397.4 0 512 114.6 512 256c0 46.6-12.5 90.4-34.3 128c-8.8 15.3-28.4 20.5-43.7 11.7s-20.5-28.4-11.7-43.7c16.3-28.2 25.7-61 25.7-96c0-106-86-192-192-192c-17.7 0-32-14.3-32-32z"/></svg>
                                    <div className="content" />
                                </div>
                            </div>
                            {showTopMenu && (<TopMenu sideMenuToggle={() => sideMenuToggle()} showTopMenu={showTopMenu} />)}
                            <div id='main-container' className={ 'p-0 d-flex h-100 position-relative ' + (location.pathname === '/' ? " login-container" : "") }>
                                <SideMenu show={ showSideMenu } sideMenuToggle={ () => sideMenuToggle() } />
                                <Switch>
                                    <Route exact path="/" render={(props) => <Login toggleTopMenuVisibility={setShowTopMenu} {...props} />} />
                                    <Route exact path={"/:locale(" + ALLOWED_LANGS_STR + ")"} render={(props) => <Login toggleTopMenuVisibility={setShowTopMenu} {...props} />} />
                                    <Route path={"/:locale(" + ALLOWED_LANGS_STR + ")/login"} render={(props) => <Login toggleTopMenuVisibility={setShowTopMenu} {...props} />} />
                                    <Route path="/login" render={(props) => <Login toggleTopMenuVisibility={setShowTopMenu} {...props} />} />
                                    <Route path={ "/:locale(" + ALLOWED_LANGS_STR + ")/logout" } component={ Logout } />
                                    <Route path="/logout" component={ Logout } />
                                    {/* Dashboard / Job Search */ }
                                    <Route path={ "/:locale(" + ALLOWED_LANGS_STR + ")/jobsearch" } component={ Dashboard } />
                                    <Route path="/jobsearch/:uuid" component={ Dashboard } />
                                    <Route path="/jobsearch/" component={ Dashboard } />

                                    <Route path="/link/:dpid/:customertype?" component={ Link } />
                                    <Route path="/user/logout" component={ Logout } />
                                    <Route path={ "/:locale(" + ALLOWED_LANGS_STR + ")/jobs/:jobid" } component={ Job } />
                                    <Route path="/job/:uuid/:paymentmethod?" component={ Job } />
                                    <Route path="/job/:uuid" component={ Job } />
                                    <Route path="/job" component={ Job } />
                                    <Route path="/payment" component={ Payment } />

                                    {/* Subscribing to web invoice*/ }
                                    <Route exact path={ "/subs/:dpid" } component={ LandingPage } />
                                    <Route path={ "/subscriptions" } component={ SubscriptionPage } />

                                    {/* Not Found page */}
                                    <Route path={"/*"} component={NotFound} />
                                    <Route path={"/:locale(" + ALLOWED_LANGS_STR + ")/*"} component={NotFound} />
                                </Switch>
                                <Footer location={location} />
                            </div>
                        </div>
                    ) } />
                </Router>
            </IntlProvider>
        </Sentry.ErrorBoundary>
    );
};

export default Main;
