import Moment from 'moment';
import { format } from 'date-fns';
// date-fns supports Norwegian Bokmål
import { fi, sv, nb } from 'date-fns/locale';
import { Cookies } from "react-cookie-consent";

let loadingAnimation = false;

// allowed languages
const ALLOWED_LANGS = ['fi', 'en', 'se', 'no'];
// allowed countries, e.g. to format dates for datepickers
const ALLOWED_COUNTRIES = ['fi', 'se', 'no'];

export function toggleLoading(on, content) {
	const elem = document.getElementById("loading");

	if (typeof elem !== "undefined" && elem !== null) {

		if (typeof on === 'undefined' || on === true) {
			loadingAnimation = true;
			elem.classList.add('visible');

			if (typeof content !== 'undefined') {
				elem.querySelector('.content').innerHTML = content;
			}
		}
		else {
			loadingAnimation = false;
			// Clear content
			elem.querySelector('.content').innerHTML = '';
			elem.classList.remove('visible');
		}
	}
}

export function changeLoadingText(text) {
	const elem = document.getElementById("loading");
	elem.querySelector('.content').innerHTML = text;
}

// can be given country as parameter, but if left undefined, uses operative country from localstorage
export function formatDate(str, formatByCountry) {
	let date;

	// format is not given as a parameter
	if (formatByCountry === undefined) {
		// fetch operative country from localstorage
		formatByCountry = localStorage.getItem('country');
	}

	// get correct localize property, use fi format as fallback
	switch (formatByCountry) {
		case 'se':
			formatByCountry = sv;
			break;
		case 'no':
			formatByCountry = nb;
			break;
		case 'fi':
		default:
			formatByCountry = fi;
			break;
	}

	// use date-fns format() to localize the date
	if (formatByCountry === fi){
		// use date-fns pattern 'dd.MM.yyyy' for Finland
		date = format(new Date(str), 'dd.MM.yyyy', { locale: formatByCountry });
	}
	else {
		// for other countries use date-fns pattern 'P' for formatting (long localized date)
		date = format(new Date(str), 'P', { locale: formatByCountry });
	}

	return date;
}

export function getIsoDateTime() {
	return Moment().toISOString();
}

export function isLoading() {
	return loadingAnimation;
}

// Validoidaan lomakkeen syöttökentät hyödyntäen HTML5 API
export function validate(formEl) {
	const formLength = formEl.length;

	// Tarkistaa, että validoidaanko lomake ilman lähetystä
	if (formEl.checkValidity() === false) {
		// Käydään läpi kaikki lomakkeen elementit
		for (let i = 0; i < formLength; i++) {
			const elem = formEl[ i ];
			// Poistetaan olemassaoleva virheluokka
			elem.classList.remove("error");
			elem.classList.remove("is-invalid");
			const errorLabel = elem.parentNode.querySelector(".invalid-feedback");

			if (errorLabel && elem.nodeName.toLowerCase() !== "button") {
				if (!elem.validity.valid) {
					// Napataan attribuutista "validationmessage" virheilmoitus ja esitetään se
					errorLabel.textContent = elem.getAttribute("validationmessage");
					// Lisätään virheluokka
					elem.classList.add("error");
					elem.classList.add("is-invalid");
				}
				else {
					// Ei virhettä
					errorLabel.textContent = "";
				}
			}
		}

		return false;
	}
	else {
		for (let i = 0; i < formLength; i++) {
			const elem = formEl[ i ];
			const errorLabel = elem.parentNode.querySelector(".invalid-feedback");
			elem.classList.remove("is-invalid");
			if (errorLabel && elem.nodeName.toLowerCase() !== "button") {
				errorLabel.textContent = "";
			}
		}

		return true;
	}
}

/**
 * List id's of input fields that have failed form validation.
 * Inputs that have failed form validation have classes 'error' and/or 'is-invalid'.
 *
 * @param {*} formEl form elements to check
 * @returns array of input id's
 */
export function listInvalidFields(formEl) {
	let invalidFields = [];

	// add invalid fields to the list
	for (const elem of formEl) {
		if (elem.nodeName.toLowerCase() !== "button" && (elem.classList.contains('error') || elem.classList.contains('is-invalid'))) {
			invalidFields.push(elem.id);
		}
	}

	return invalidFields;
}

/**
 * Check if a cookie with given name exists.
 *
 * @param name
 * @return {boolean}
 */
export function hasCookie(name) {
	// getCookie only returns false if the cookie doesnt exist
	return getCookie(name) !== false;
}

/**
 * Get a single cookie
 * @param name
 * @return mixed
 */
export function getCookie(name) {
	let response = false;
	const cookieArr = document.cookie.split(";");

	// Loop through the array elements
	for(let i = 0; i < cookieArr.length; i++) {
		const cookiePair = cookieArr[i].split("=");

		if (name === cookiePair[0].trim()) {
			// Decode the cookie value and return
			response = decodeURIComponent(cookiePair[1]);
		}
	}

	return response;
}

/**
 * Maps provided locale code to http headers compliant BCP 47 (IETF) language code
 *
 * @param locale
 * @returns {string}
 */
export function mapLocaleToHeaders(locale) {
	switch(locale) {
		case 'se':
			locale = 'sv-SE';
			break;
		case 'en':
			locale = 'en-GB';
			break;
		case 'no':
			locale = 'nb-NO';
			break;
		case 'fi':
		default:
			locale = 'fi-FI';
			break;
	}

	return locale;
}

/**
 * Maps provided locale code to http headers compliant BCP 47 (IETF) language code
 *
 * @param locale
 * @returns {string}
 */
export function mapLocaleToBCP47(locale) {
	switch(locale) {
		case 'se':
			locale = 'sv_SE';
			break;
		case 'en':
			locale = 'en_GB';
			break;
		case 'no':
			locale = 'nb_NO';
			break;
		case 'fi':
		default:
			locale = 'fi_FI';
			break;
	}

	return locale;
}

export function formatMoney(value, locale, currency) {
	let response = value;

    if (locale.length === 2) {
        locale = mapLocaleToHeaders(locale);
    }

    // Currency has to be three letter code, otherwise use fallback (usually env variable value)
    if (typeof currency !== 'undefined' && currency && currency.length === 3) {
		response = value.toLocaleString(locale, {
			style: 'currency',
			currency: currency.toUpperCase()
		});
    }

    return response;
}

export function parseLinkVariable(locale, variable) {
	if(variable && variable !== '') {
		// Split by double ||
		let links = variable.split('||');

		if (links.length > 0) {
			for (let i = 0; i < links.length; i++) {
				const link = links[i];

				// Assume link is {LOCALE}:https://www.example.com/...
				let parts = link.split(':');
				const linkLocale = parts[0];

				if (linkLocale.toUpperCase() === locale.toUpperCase()) {
					parts.shift();
					return parts.join(':');
				}
			}
		}
	}
}

/**
 * Get locale-specific string value from object
 * @param locale
 * @param values
 * @returns {string}
 */
export function parseVariableString(locale, values) {
    let ret = '';
    
    if (typeof values !== 'undefined' && values !== null) {
        if (values.hasOwnProperty(locale)) {
            ret = values[locale];
        }
    }
    
    return ret;
}

// retrieves allowed languages
export function getAllowedLangs() {
	return ALLOWED_LANGS;
}

// retrieves allowed languages
export function getAllowedCountries() {
	return ALLOWED_COUNTRIES;
}

// adds Dynatrace
export function addDynatrace() {
	const scripts = document.getElementsByTagName('script');
	const url = process.env.PUBLIC_URL + "/js/dynatrace.js";

	if (!(scripts[0].src).includes(url)){
		let script = document.createElement('script');
		script.type = 'text/javascript';
		script.src = url;

		let s = document.getElementsByTagName('script')[0];
		s.parentNode.insertBefore(script, s);
	}
}

// removes Dynatrace
export function removeDynatrace() {
	const scripts = document.getElementsByTagName('script');
	const url = process.env.PUBLIC_URL + "/js/dynatrace.js";

	for (const script of scripts) {
		if ((script.src).includes(url)) {
			document.head.removeChild(script);
		}
	}
}

/**
 * Set or remove cookies. 
 * In addition, toggle Dynatrace on / off based on the cookies (accepted / declined).
 * 
 * @param {*} value Cookie consent value (true/false)
 * @param {*} dynatrace is use of Dynatrace allowed (true/false)?
 */
export function updateCookies(value, dynatrace) {
	if (value) {
		Cookies.set('CookieConsent', 'true', { expires: 365 });

		// also add Dynatrace if allowed in the environment
		if (dynatrace) {
			addDynatrace();
		}

	} else {
		Cookies.remove('CookieConsent');

		// also remove Dynatrace if allowed in the environment
		if (dynatrace) {
			removeDynatrace();
		}
	}
}