import React, {useState, useEffect} from "react";
import { NavLink, withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { far } from '@fortawesome/pro-regular-svg-icons';
import PropTypes from "prop-types";
import { injectIntl, defineMessages } from "react-intl";
import ToggleChatButton from "../ToggleChatButton";
import { userGetName, userGetToken } from "../../services/user";
import { jobGetJob } from "../../services/job";
import isEmpty from "lodash.isempty";

// Services
import { userIsAuthorized } from "../../services/user";
import { role } from "../../services/helpers/role";
import FeedbackDialog from "../FeedbackDialog";
import { Button } from "react-bootstrap";

export const messages = defineMessages({
    invoices: {
        id: "Laskut",
        defaultMessage: "Laskut"
    },
    logout: {
        id: "Kirjaudu ulos",
        defaultMessage: "Kirjaudu ulos"
    }
});

const SideMenu = (props) => {
    // Chat parameters
    const [livechatParameters, SetLivechatParameters] = useState([]);

    const lng = props.intl.formatMessage;
    const userAuthorized = userIsAuthorized(role.authenticated);
    const [toggleFeedbackDialog, setToggleFeedbackDialog] = useState(false);

    const links = [
        { label: lng(messages.invoices), icon: far.faReceipt, link: '/jobsearch', notifications: 0, onClickFunc: props.sideMenuToggle }
    ];

    let feedBackToggle = false;

    // Is customer feedback on or off
    if (typeof process.env.REACT_APP_FEEDBACK_TOGGLE !== 'undefined' && process.env.REACT_APP_FEEDBACK_TOGGLE.toString() === '1') {
        feedBackToggle = true;
    }

    const menuItems = links.map(function (link) {

        let badge = "";

        if (link.notifications > 0) {
            badge = <span className="notification-badge">{link.notifications}</span>;
        }

        return (
            <li key={link.link} className="nav-item mb-4">
                <NavLink className="position-relative" to={link.link} role="menuitem" onClick={() => link?.onClickFunc ? link.onClickFunc() : ''} ><FontAwesomeIcon icon={link.icon} /> {link.label} {badge}</NavLink>
            </li>
        );
    });

    let page = window.location.hash;

    const job = jobGetJob();
    useEffect(() => {
        // Token is mandatory for chat to work
        if (!isEmpty(userGetToken())) {
            if (!isEmpty(job) && window.location.hash === '#/job') {
                SetLivechatParameters([
                    {name: "jobid", value: job.jobid},
                    {name: "token", value: userGetToken()}
                ]);
            } else {
                SetLivechatParameters([
                    {name: "token", value: userGetToken()}
                ]);
            }
        }

    }, [page]);

    /**
     * Log out user and close and hide chat
     */
    function confirmLogout() {
        props.history.push("/logout");
        // Close and hide chat when user logs out
        if (window.LC_API) {
            window.LC_API.close_chat();
            window.LC_API.hide_chat_window();
        }
    }

    /**
     * Open feedback dialog when logging out.
     */
    function handleLogOut() {
        if (feedBackToggle) {
            toggleFeedbackDialog ? setToggleFeedbackDialog(false) : setToggleFeedbackDialog(true);
        } else {
            confirmLogout();
        }
    }

    return (
        <>
            {userAuthorized && (
                <>
                    <nav id="sidemenu" className={"d-flex flex-column flex-shrink-0 bg-white text-white offcanvas-xl offcanvas-start h-auto " + (props.show ? "show" : "")} aria-label="Main">
                        <NavLink to="/jobsearch" className="navbar-brand" onClick={() => props.sideMenuToggle()}>
                            <svg width="128" height="34" viewBox="0 0 128 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M68.3256 19.5716C68.3256 15.4565 65.9223 13.7503 62.3508 14.2187C62.3508 14.2187 69.3603 13.2819 69.3603 7.25989C69.3603 1.23786 66.3562 0 59.7807 0H49.6337V26.2293H52.838V19.5047C52.838 18.9694 53.0716 18.501 53.4388 18.133C54.8073 16.8951 57.1104 15.6907 59.6806 14.9882C63.0184 14.0848 64.921 15.3562 64.921 19.3039C64.921 23.2517 65.6553 25.8947 65.8556 26.1958H69.2935C69.1934 25.9282 69.0933 25.5602 68.9597 25.0918C68.8262 24.5899 68.2588 22.7499 68.2588 19.5381L68.3256 19.5716ZM58.7126 12.479C56.6097 13.0477 54.5069 13.7168 52.9047 15.0216V2.77682H59.6472C61.1492 2.77682 66.0558 2.20808 66.0558 6.59077C66.0558 10.9735 64.4203 10.5385 62.2173 11.3749C61.1492 11.7764 59.9476 12.111 58.7126 12.4455V12.479Z" fill="black"/>
                                <path d="M99.5343 7.09262C94.8279 7.09262 93.159 10.6724 93.1256 10.7393V7.62791H89.8879V32.7197H93.1256V23.1179C93.426 23.6197 94.9281 26.7646 99.5343 26.7646C104.141 26.7646 107.946 23.2183 107.946 16.9621C107.946 10.7058 104.007 7.12608 99.5343 7.12608V7.09262ZM98.6665 23.8205C93.8933 23.8205 92.9254 19.0698 92.9254 16.9286C92.9254 14.7874 93.8933 10.0033 98.9669 10.0033C104.04 10.0033 104.474 13.3488 104.474 16.9286C104.474 20.5084 102.038 23.8205 98.6665 23.8205Z" fill="black"/>
                                <path d="M77.1374 7.22646C72.1307 8.26358 68.9263 13.4492 69.9611 18.7687C70.9958 24.1216 75.9358 27.601 80.9426 26.5304C85.9493 25.4933 89.1536 20.3076 88.1189 14.9882C87.0842 9.63527 82.1442 6.15587 77.1374 7.22646ZM80.542 24.4896C77.4712 25.1253 74.3003 22.2481 73.4658 18.0326C72.6313 13.8172 74.4672 9.90291 77.538 9.26725C80.6088 8.6316 83.7797 11.5088 84.6142 15.7242C85.4486 19.9396 83.6128 23.8539 80.542 24.4896Z" fill="black"/>
                                <path d="M116.824 7.25988C111.818 8.29701 108.613 13.4826 109.648 18.8021C110.683 24.155 115.623 27.6344 120.63 26.5638C125.636 25.5267 128.841 20.3411 127.806 15.0216C126.771 9.70215 121.831 6.1893 116.824 7.25988ZM120.229 24.5565C117.158 25.1921 113.987 22.3149 113.153 18.0995C112.318 13.8841 114.154 9.96979 117.225 9.33414C120.296 8.69848 123.467 11.5757 124.301 15.7911C125.136 20.0065 123.3 23.9208 120.229 24.5565Z" fill="black"/>
                                <g opacity="0.5">
                                <path d="M13.4181 22.5157L5.54081 0.13385H0V26.2628H3.57149L3.37122 4.14853L11.4154 26.2628H15.3207L23.4316 4.14853L23.198 26.2628H26.8362V0.13385H21.262L13.4181 22.5157Z" fill="black"/>
                                <path d="M38.5521 21.8466L32.377 7.66138H28.6387L36.6161 25.7944L35.8484 27.6679C34.3464 30.8796 31.9431 31.3146 29.7068 30.7458V33.7234C34.046 34.7939 37.4172 32.7532 39.52 27.6679L47.8646 7.66138H44.2931L38.5854 21.8466H38.5521Z" fill="black"/>
                                </g>
                            </svg>
                        </NavLink>
                        <ul className="sidemenu nav nav-pills flex-column mr-5" id="menu">
                            {menuItems}
                        </ul>
                <div className="sidemenu-border">
                    <ul className="sidemenu nav nav-pills flex-column" id="menu">
                        { !isEmpty(livechatParameters) ? (
                            <li className="nav-item-small">
                                <ToggleChatButton visitor={ {name: userGetName(), email: ''} } parameters={ livechatParameters } location={ 'sidemenu' } />
                            </li>
                        ) : ('')
                        }

                        <li className="nav-item-small">
                            <Button type="button" className={ "actionlist-button" } variant='tertiary-sidemenu'
                                    onClick={ () => handleLogOut() }>
                                <FontAwesomeIcon icon={far.faRightFromBracket}/>
                                {lng(messages.logout)}
                            </Button>
                        </li>

                    </ul>
                </div>
                        <button onClick={props.sideMenuToggle} onKeyDown={props.sideMenuToggle} className="sidemenuclose d-xl-none">
                            <FontAwesomeIcon icon={far.faXmark} />
                        </button>
                    </nav>
                    <div className={ "offcanvas-backdrop fade " + (props.show ? "show d-xl-none" : "d-none") }></div>
                    {toggleFeedbackDialog ? (
                        <FeedbackDialog title={lng(messages.logout)} canShow={true} show={toggleFeedbackDialog} handleConfirmDialog={handleLogOut} {...props} />
                    ) : ('')
                    }
                </>
            )}
        </>
    );
};

SideMenu.propTypes = {
    'intl': PropTypes.object.isRequired,
    'show': PropTypes.bool.isRequired,
    'sideMenuToggle': PropTypes.func.isRequired,
    'history': PropTypes.object
};

export default withRouter(injectIntl(SideMenu));